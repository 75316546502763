import cn from 'classnames'

import useDateCurrency from 'lib/hooks/useDateCurrency'
import useTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'

type KfMilesPriceSize = 'large' | 'medium' | 'small'

type KfMilesPriceProps = {
  milesPrice: number
  variant?: 'price-block' | 'default'
  size?: KfMilesPriceSize
}

const KfMilesPrice = ({ milesPrice, variant = 'default', size = 'medium' }: KfMilesPriceProps) => {
  const { formatNumber } = useDateCurrency()
  const { t } = useTranslation()
  const isPriceBlock = variant === 'price-block'

  return (
    <div className={cn(s.kfMilesPrice, s[`variant-${variant}`], s[`size-${size}`])}>
      {isPriceBlock ? (
        <>
          <p>{formatNumber(milesPrice)}</p>
          <span>{t('krisFlyerMiles.t', { ns: 'booking' })}</span>
        </>
      ) : (
        <p>
          {formatNumber(milesPrice)} {t('krisFlyerMiles.t', { ns: 'booking' })}
        </p>
      )}
    </div>
  )
}

export { KfMilesPrice }
