import { useMemo } from 'react'

import {
  MERCHANDISE_LABELS,
  getProductMerchandiseLabel,
  getProductPlaceholderImages,
  formatToNearestValue,
} from 'page-modules/product/utils'

import useDateCurrency from 'lib/hooks/useDateCurrency'
import useCustomTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import { MILLION_VALUE, THOUSAND_VALUE } from 'lib/constants'

interface ProductCardProps {
  data: Product | undefined
  showDestination?: boolean
  showProductRank?: boolean
  index?: number
}

const SMALL_PRICE_THRESHOLD = 1_000_000

const useProductCard = ({ data, showDestination, showProductRank, index = 0 }: ProductCardProps) => {
  const { t } = useCustomTranslation(['common', 'product'])
  const {
    destinationId,
    destinationName,
    currency,
    priceRangeFrom,
    strikeOffDiscountValue,
    rating,
    reviewCount,
    productTags,
  } = data || {}
  const { formatNumber } = useDateCurrency()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()

  const showWishlist = useMemo(() => showFeature('wishlist'), [showFeature])
  const showStrikeOffPrice = !!(strikeOffDiscountValue && strikeOffDiscountValue > 0)

  const merchandiseLabelMap = useMemo(() => {
    if (showProductRank) return null

    return {
      [MERCHANDISE_LABELS.TAG_SPECIAL_DEALS]: t('t.deal', { ns: 'common' }),
      [MERCHANDISE_LABELS.TAG_PELAGO_EXCLUSIVE]: t('t.exclusive', { ns: 'common' }),
    }
  }, [t, showProductRank])

  const merchandiseLabel = useMemo(() => {
    if (!merchandiseLabelMap) return null

    const productMerchandiseLabel = getProductMerchandiseLabel(productTags, merchandiseLabelMap)
    return productMerchandiseLabel?.merchandiseLabel
  }, [productTags, merchandiseLabelMap])

  const productRank = useMemo(() => {
    if (!showProductRank) return null

    return `${index + 1}`
  }, [showProductRank, index])

  const imageSrc = useMemo(() => {
    if (data?.thumbnail?.sizes) {
      return data?.thumbnail
    } else if (data?.mediaData?.[0]?.sizes) {
      return data?.mediaData?.[0]
    } else {
      return { ...data?.thumbnail, ...getProductPlaceholderImages() }
    }
  }, [data?.mediaData, data?.thumbnail])

  const destinationNameDisplay = useMemo(() => {
    if (!showDestination || !destinationId) return null

    return destinationName ?? destinationId
  }, [destinationId, destinationName, showDestination])

  const reviewCountDisplay = useMemo(() => {
    if (!reviewCount) return undefined

    const isExceedMillion = reviewCount >= MILLION_VALUE
    const isExceedThousand = reviewCount >= THOUSAND_VALUE
    if (isExceedMillion || isExceedThousand) {
      return formatToNearestValue(reviewCount)
    }
    return reviewCount
  }, [reviewCount])

  const ratingDislay = useMemo(() => {
    if (!rating) return undefined

    return rating.toFixed(1)
  }, [rating])

  const priceDisplay = useMemo(() => {
    if (!priceRangeFrom) return undefined

    return `${currency} ${formatNumber(priceRangeFrom)}`
  }, [currency, priceRangeFrom, formatNumber])

  const hasStrikeOffPrice = useMemo(() => {
    return showStrikeOffPrice && (priceRangeFrom || 0) >= SMALL_PRICE_THRESHOLD
  }, [showStrikeOffPrice, priceRangeFrom])

  return {
    showWishlist,
    showStrikeOffPrice,
    merchandiseLabel,
    productRank,
    destinationNameDisplay,
    imageSrc,
    reviewCountDisplay,
    priceDisplay,
    ratingDislay,
    hasStrikeOffPrice,
  }
}

export default useProductCard
