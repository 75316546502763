import s from './styles.module.scss'

const RankLabel = ({ label }: { label: string }) => {
  return (
    <div className={s.rankLabel}>
      <span>{label}</span>
    </div>
  )
}

export { RankLabel }
