import { TopBadge } from 'components/top-badge'

import s from './styles.module.scss'

const TopBadgeWithDestination = ({
  destinationName,
  productBucket,
}: {
  destinationName: string
  productBucket: ProductBucket
}) => {
  return (
    <div className={s.badgeContainer}>
      <div className={s.destinationName}>{destinationName}</div>
      <TopBadge text={productBucket?.label} />
    </div>
  )
}

export { TopBadgeWithDestination }
