import React from 'react'
import cn from 'classnames'

import Leaf from 'brand-assets/illustrations/leaf.svg'

import s from './styles.module.scss'

interface TopBadgeProps {
  text: string
  size?: 'small' | 'medium' | 'large'
  color?: 'gold' | 'white'
}
const TopBadge = ({ text, size = 'small', color = 'gold' }: TopBadgeProps) => {
  return (
    <div className={cn(s.badge, s[`size-${size}`], s[`color-${color}`])}>
      <Leaf size="small" className={s.icon} />
      <div className={s.text}>{text}</div>
      <Leaf size="small" className={cn(s.icon, s.flipHorizontal)} />
    </div>
  )
}

export { TopBadge }
