import { ProductCard as VerticalProductCard } from './vertical'
import { ProductCard as HorizontalProductCard } from './horizontal'
import { ProductCardProps } from './types'

const ProductCard = (props: ProductCardProps) => {
  const { variant = 'vertical' } = props

  if (variant === 'vertical') return <VerticalProductCard {...props} />
  return <HorizontalProductCard {...props} />
}

export { ProductCard }
