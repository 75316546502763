import cn from 'classnames'

import { Image } from 'ui/image'

import { ProductDetailsLink } from 'components/product-details-link'
import { StrikedOffPrice } from 'components/striked-off/striked-off-price'
import { PercentDiscoutCallout } from 'components/striked-off'
import { KfMilesPrice } from 'components/kf-miles-price'

import { Wishlist } from 'page-modules/product/wishlist'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { KF_MILES } from 'lib/constants'

import StarIcon from 'brand-assets/illustrations/icons/rating-star.svg'

import useProductCard from '../useProductCard'
import { ProductCardProps } from '../types'
import { RankLabel } from '../rank-label'

import s from './styles.module.scss'

const ProductCard = ({
  size,
  data,
  loading,
  imageSize,
  imageLazyLoadOffset,
  disableLazyloadImage,
  indexInList,
  isPinnedCard,
  hideWishlist,
  showDestination,
  callout,
  children,
  showProductRank,
  border,
  refetch,
  onClick,
}: ProductCardProps) => {
  const { t } = useTranslation(['common', 'product'])
  const {
    productId = '',
    productUri,
    productName,
    destinationId,
    currency,
    priceRangeFrom,
    strikeOffDiscountValue,
    strikeOffDiscountType,
    cancellationType,
  } = data || {}
  const isMilesExclusive = currency === KF_MILES

  const {
    showWishlist,
    showStrikeOffPrice,
    merchandiseLabel,
    productRank,
    imageSrc,
    reviewCountDisplay,
    priceDisplay,
    ratingDislay,
    hasStrikeOffPrice,
  } = useProductCard({ data, showDestination, showProductRank, index: indexInList })
  const { isMobileView } = useGlobalContext()

  const cardSize: ProductCardProps['size'] = size ? size : isMobileView ? 'small' : 'medium'

  if (loading) {
    return (
      <div className={cn(s.productCard, s.loading)}>
        <div className={s.imageContainer} />
        <div className={s.body}>
          <div className={s.productName} />
          <div className={s.productName} />
          <div className={s.label} />
          <div className={s.footer}>
            <div className={s.priceAndLabel} />
          </div>
        </div>
      </div>
    )
  }

  if (!data) {
    return null
  }

  return (
    <ProductDetailsLink
      productId={productId}
      productUri={productUri}
      destinationId={destinationId}
      underline={false}
    >
      <div
        className={cn(s.productCard, s[cardSize], s[`border-${border}`], { [s.pinned]: isPinnedCard })}
        onClick={onClick}
      >
        <div className={s.imageContainer}>
          <Image
            size={imageSize || 'medium'}
            layout="fill"
            src={imageSrc}
            alt={productName}
            lazyLoadOffset={imageLazyLoadOffset}
            lazyLoad={!disableLazyloadImage ? true : disableLazyloadImage?.indexOf?.(indexInList) === -1}
          >
            {!hideWishlist && showWishlist && (
              <div className={s.wishlistBtn}>
                <Wishlist
                  productId={productId}
                  destinationId={destinationId}
                  product={data}
                  refetchProduct={refetch}
                  variant="static"
                />
              </div>
            )}
            {(callout || merchandiseLabel) && <div className={s.callout}>{callout || merchandiseLabel}</div>}
            {productRank && (
              <div className={s.topTen}>
                <RankLabel label={productRank} />
              </div>
            )}
          </Image>
        </div>
        <div className={s.body}>
          <div className={s.header}>
            <h3 className={s.productName}>{productName}</h3>
            {!!ratingDislay && (
              <div className={s.rating}>
                <span className={s.ratingIcon}>
                  <StarIcon />
                </span>
                {ratingDislay}
                {reviewCountDisplay && <span className={s.reviewCount}>({reviewCountDisplay})</span>}
              </div>
            )}
            {cancellationType === 'ALLOW_CANCELLATION' && (
              <div className={isMobileView ? `mt-1` : ''}>
                <span className={s.label}>{t('freeCancelation', { ns: 'product' })}</span>
              </div>
            )}
          </div>
          <div className={s.footer}>
            <div className={s.priceAndLabel}>
              <div>
                <div className={s.fromPriceValue}>
                  <span>{t('t.from', { ns: 'common' })}</span>
                  {showStrikeOffPrice && (
                    <span>
                      <StrikedOffPrice
                        currency={currency}
                        discount={strikeOffDiscountValue}
                        discountType={strikeOffDiscountType}
                        total={priceRangeFrom}
                      />
                    </span>
                  )}
                </div>
                <span
                  className={cn(s.priceValue, {
                    [s.smallText]: hasStrikeOffPrice,
                  })}
                >
                  {isMilesExclusive ? (
                    <KfMilesPrice milesPrice={priceRangeFrom || 0} variant="price-block" size="small" />
                  ) : (
                    priceDisplay
                  )}
                  {showStrikeOffPrice && (
                    <PercentDiscoutCallout
                      discount={strikeOffDiscountValue}
                      discountType={strikeOffDiscountType}
                      calloutSize="mini"
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </ProductDetailsLink>
  )
}

export { ProductCard }
